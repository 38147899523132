import React, { useEffect, useMemo, useRef, useState } from "react"
import { ButtonResult, ButtonType, PopupSize, popup } from "ui";
import { Inventories } from "../Inventories/Inventories";
import * as styles from './SparePartsList.module.scss';
import { Item } from "./Item";
import { SearchResultItem } from "../../types";


const translationState = (window as any).app.preloadState.translation;
const showInventoryPopup = (item: SearchResultItem, ev: React.MouseEvent) => {

    ev.preventDefault();

    popup(
        translationState["inventoryPopup.stockStatusAtFacility"],
        <Inventories itemCode={item.code} itemDisplayName={item.displayName} />,
        [
            { label: translationState["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Large
    );
}

export const SparePartsList = ({ items, total, pageCallback }: { items: SearchResultItem[], total: number, pageCallback: (page: number) => Promise<SearchResultItem[]> }) => {

    const numberOfDivs = items.length > 0 ? Math.ceil(total / items.length) : 1;

    const mapping = Array.from(Array(numberOfDivs - 1).keys())

    return <>
        <div className={styles.sparePartItems}>
            {items.map(item => <Item
                key={item.code}
                item={item}
                showInventoryPopup={showInventoryPopup}
            />)}
        </div>
        {mapping.map((x) => {
            return <SparePartsObserver key={x} page={x + 2} pageCallback={pageCallback} />
        })}
    </>
}

const SparePartsObserver = ({ page, pageCallback }: { page: number, pageCallback: (page: number) => Promise<SearchResultItem[]> }) => {
    const [items, setItems] = useState<SearchResultItem[]>([]);
    const loaded = useRef(false);

    const observer = useMemo(() => {
        const options = {
            root: document.rootElement,
            rootMargin: "200px 0px",
            threshold: 0,
        };

        const callback = async (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting && !loaded.current) {
                const data = await pageCallback(page);
                setItems(data);
                loaded.current = true;
            }
        }

        return new IntersectionObserver(callback, options);
    }, []);

    useEffect(() => {
        let target = document.querySelector(`#list_${page}`);
        observer.observe(target!);
    }, [])

    return <>
        {items.length ?
            <div className={styles.sparePartItems}>
                {items.map(item => <Item
                    key={item.code}
                    item={item}
                    showInventoryPopup={showInventoryPopup}
                />)}
            </div>
            :
            <div id={`list_${page}`} className={styles.itemsPlaceholder}>

            </div>
        }
        </>
}
