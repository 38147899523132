import React, { useEffect, useState } from "react";
import { Loader } from "../Loader/Loader";
import { RemanChoices, RemanResults, RemanufacturedPopUpItemDetails } from "../../types";

const translations = (window as any).app.preloadState.translation;
export function RemanufacturedChoice({ changeResult, sparePartIds, quantityOverride }: { changeResult: (results: RemanResults) => void, sparePartIds: string[], quantityOverride?: Record<string, number>}) {

    const [choices, setChoices] = useState<RemanChoices>({});
    const [chosenResult, setChosenResult] = useState<RemanResults>(
        Object.fromEntries(sparePartIds.map(id => ([id, { selectedReman: true, sparePartQuantity: 0, remanQuantity: 0 }] as const)))
    );
    const [totalSum, setTotalSum] = useState<string>("0");

    useEffect(() => {
        changeResult(chosenResult);
    }, [chosenResult]);

    useEffect(() => {
        (async () => {
            const itemDetailsResponse = await fetch(`/api/pdp/reman-item-details`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                },
                body: JSON.stringify(sparePartIds),
                method: "POST"
            });

            if (!itemDetailsResponse.ok) {
                console.error(await itemDetailsResponse.text());
            }
            else {
                var choices: RemanChoices = await itemDetailsResponse.json();
                setChoices(choices);
                setChosenResult(
                    Object.fromEntries(
                        sparePartIds.map(id => ([id, {
                            selectedReman: true,
                            sparePartQuantity: quantityOverride?.[id] ?? choices[id]?.sparePartDetails.quantity ?? 0,
                            remanQuantity: quantityOverride?.[id] ?? choices[id]?.remanPartDetails.quantity ?? 0
                        }] as const))
                    )
                );
            }
        })();
    }, [...sparePartIds]);

    useEffect(() => {
        (async () => {
            const response = await fetch(`/api/pdp/reman-items-total-price`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                },
                body: JSON.stringify({ ...chosenResult }),
                method: "POST"
            });
            if (!response.ok) {
                console.error(await response.text())
            } else {
                setTotalSum(await response.json());
            }
        })();
    }, [chosenResult])


    return <>{Object.keys(choices).length > 0 ?
        <div className={"remanufacturedGrids"}>{sparePartIds.map(sparePartId => {
            const sparePartItem = choices[sparePartId]!.sparePartDetails;
            const remanItem = choices[sparePartId]!.remanPartDetails;
            const depositItem = choices[sparePartId]!.depositDetails;
            const chosenData = chosenResult[sparePartId];

            return (<div className={"remanufacturedItemGrid"} key={sparePartId}>
                <div className={"remanufacturedHeaders font-weight-bold"}>
                    <div className={"remanufacturedC2 justifyStart"}>{translations["remanufacturedItemPopUp.articleNo"]}</div>
                    <div className={"remanufacturedC3"}>{translations["remanufacturedItemPopUp.description"]}</div>
                    <div className={"remanufacturedC4"}>{translations["remanufacturedItemPopUp.displayName"]}</div>
                    <div className={"remanufacturedC5"}>{translations["remanufacturedItemPopUp.unit"]}</div>
                    <div className={"remanufacturedC6"}>{translations["remanufacturedItemPopUp.quantity"]}</div>
                    <div className={"remanufacturedC7"}>{translations["remanufacturedItemPopUp.webStorage"]}</div>
                    <div className={"remanufacturedC8"}>{translations["remanufacturedItemPopUp.facility"]}</div>
                    <div className={"remanufacturedC9 justifyEnd"}>{translations["remanufacturedItemPopUp.price"]} <span className="remanufacturedTextSmall">({translations["remanufacturedItemPopUp.discountIncluded"]})</span></div>
                </div>

                <div className={"remanufacturedGroup cursor-pointer"} onClick={() => setChosenResult({ ...chosenResult, [sparePartId]: { ...chosenData, selectedReman: false } })}>
                    <div className={"remanufacturedC1"}>
                        <div className="form-radio">
                            <input type="radio" checked={!chosenData.selectedReman} onChange={() => setChosenResult({ ...chosenResult, [sparePartId]: { ...chosenData, selectedReman: false } })} />
                            <label></label>
                        </div>
                    </div>
                    <div className={"remanufacturedC2_3 justifyStart font-weight-bold"}>{translations["remanufacturedItemPopUp.originalSparePart"]}</div>
                </div>
                <RemanufacturedItem item={sparePartItem} quantity={chosenData.sparePartQuantity} updateItemQuantity={(q) => setChosenResult({ ...chosenResult, [sparePartId]: { ...chosenData, sparePartQuantity: Math.max(1, q), remanQuantity: Math.max(1, q) } })} />
                <div className={"remanufacturedGroup cursor-pointer"} onClick={() => setChosenResult({ ...chosenResult, [sparePartId]: { ...chosenData, selectedReman: true } })}>
                    <div className={"remanufacturedC1"}>
                        <div className="form-radio">
                            <input type="radio" checked={chosenData.selectedReman} onChange={() => setChosenResult({ ...chosenResult, [sparePartId]: { ...chosenData, selectedReman: true } })} />
                            <label></label>
                        </div>
                    </div>
                    <div className={"remanufacturedC2_3 justifyStart font-weight-bold"}>{translations["remanufacturedItemPopUp.remanufacturedPart"]}</div>
                </div>
                <RemanufacturedItem item={remanItem} quantity={chosenData.remanQuantity} updateItemQuantity={(q) => setChosenResult({ ...chosenResult, [sparePartId]: { ...chosenData, remanQuantity: Math.max(1, q), sparePartQuantity: Math.max(1, q) } })} />
                <RemanufacturedItem item={depositItem} quantity={chosenData.remanQuantity} isDisabled={true} showAvailability={false} />
                <hr />
            </div>);
        })}
            <div className={"remanufacturedPopUpPrice font-weight-bold"}>
                <div className={"totalPriceText justifyStart"}>{translations["remanufacturedItemPopUp.totalPrice"]}</div>
                <div className={"totalPriceValue justifyEnd"}>{totalSum}</div>
            </div>
        </div>
        :
        <Loader size={28} />}
    </>
}

const RemanufacturedItem = ({ item, updateItemQuantity = () => { }, isDisabled = false, showAvailability = true, quantity }: { item: RemanufacturedPopUpItemDetails, updateItemQuantity?: (quantity: number) => void, isDisabled ?: boolean, showAvailability?: boolean, quantity: number }) => {
    const [price, setPrice] = useState(item.price);

    useEffect(() => {
        (async () => {
            const response = await fetch(`/api/pdp/${item.code}/price?quantity=${quantity}`, {
                headers: {
                    "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
                },
                method: "GET"
            });
            if (!response.ok) {
                console.error(await response.text())
            } else {
                setPrice(await response.json());
            }
        })();
    }, [quantity]);

    return (
        <div className={"remanufacturedItem"}>
            <div className={"remanufacturedC2 justifyStart"}>{item.code}</div>
            <div className={"remanufacturedC3"}>{item.description}</div>
            <div className={"remanufacturedC4"}>{item.displayName}</div>
            <div className={"remanufacturedC5"}>{item.unit}</div>
            <div className={"remanufacturedC6 inputsColumn"}>
                <input disabled={isDisabled} type="number" value={quantity} onChange={ev => !isDisabled && updateItemQuantity(ev.target.valueAsNumber ?? 1)}/>
            </div>
            {showAvailability && <div className={"remanufacturedC7 firstLetterCapital truckIcon " + (item.availability ? "greenText" : "redText")}>{item.availability ? (<span dangerouslySetInnerHTML={{ __html: item.availability ?? "" }}></span>) : translations["inventoryPopup.notInStock"]}</div>}
            {showAvailability && <div className={"remanufacturedC8 " + (item.isInBranch ? "greenText" : "redText")}>{item.branch}</div>}
            <div className={"remanufacturedC9 justifyEnd"}>{price}</div>
        </div>
    );
}