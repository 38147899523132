import { useEffect, useState } from "react";

export const usePromise = <T>(getter: () => Promise<T>, deps: unknown[]) => {
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        getter().then(v => {
            setData(v);
        });
    }, deps);

    return {
        data,
        isLoading: data == null,
    }
}