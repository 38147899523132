import { Cart } from '../types';
import { Observable } from './observable';
import { useEffect } from 'react';

type Matomo = {
    getAsyncTrackers(): MatomoTracker[];
}

type MatomoTracker = {
    clearEcommerceCart(): void;
    addEcommerceItem(code: string, name: string, labels: string | string[], unitPrice: number, quantity: number): void;
    trackEcommerceCartUpdate(cartTotal: number): void;
}

declare global {
    interface Window {
        cart: Observable<Cart>,
        Matomo: Matomo | undefined;
    }
}

export const ensureCart = () => {
    if (window.cart) return;

    window.cart = Observable(async () => {
        const response = await fetch('/api/cart?noValidation=true', {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const data = await response.json() as Cart;

        sendCartToMatomo(data);

        return data;
    })
}

export const useCartChange = (callback: (cart: Cart) => void) => {
    ensureCart();
    useEffect(() => {
        window.cart.subscribe(callback);
        return () => window.cart.unsubscribe(callback);
    }, [callback]);
}

export const updateCart = () => window.cart?.update();


function sendCartToMatomo(cart: Cart) {
    if (!cart.isMatomoEnabled) {
        return;
    }
    if (!window.Matomo) {
        return;
    }
    const tracker = window.Matomo.getAsyncTrackers()[0];
    if (!tracker) {
        return;
    }
    tracker.clearEcommerceCart();
    cart.lineItems.forEach(item => {
        tracker.addEcommerceItem(item.code, item.displayName ?? "", item.categories, item.unitPriceAmount, item.quantity);
    });
    tracker.trackEcommerceCartUpdate(cart.summary.totalAmount);
}