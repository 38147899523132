import { StockAvailability } from "../types";
import { updateCart } from "../utilities";

export const useCartItemUpdate = (code: string) => {
    return async (quantity: number) => {
        await updateCartItem(code, quantity);
    };
}

export const updateCartItem = async (code: string, quantity: number) => {
    await updateRemanItemBySparePartCode(code, 0);
    await updateItem(code, quantity);
    await updateCart();
}

export const addRemanAndDepositItemsToCart = async (code: string, quantity: number) => {
    await updateItem(code, 0);
    await updateRemanItemBySparePartCode(code, quantity);
    await updateCart();
}

export const updateRemanAndDepositCartItem = async (remanCode: string, depositCode: string, quantity: number) => {
    await updateRemanAndDeposit(remanCode, depositCode, quantity);
    await updateCart();
}

export const getAvailabilityDisplay = async (code: string): Promise<StockAvailability> => {
    const response = await fetch(`/api/inventory/warehouse/byVariant/${code}/availability`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        method: "GET"
    });
    return await response.json();
}

const updateItem = async (code: string, quantity: number) => {
    const response = await fetch(`/api/cart/items/update`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        body: JSON.stringify({ code, quantity }),
        method: "POST"
    });
    const data = await response.json();
    if (!data.isSuccess) {
        console.error(JSON.stringify(data))
    }
}

const updateRemanAndDeposit = async (remanCode: string, depositCode : string, quantity: number) => {
    const response = await fetch(`/api/cart/items/reman-and-deposit-update`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        body: JSON.stringify({ remanCode, depositCode, quantity }),
        method: "POST"
    });
    const data = await response.json();
    if (!data.isSuccess) {
        console.error(JSON.stringify(data))
    }
}

const updateRemanItemBySparePartCode = async (sparePartCode: string, quantity: number) => {
    const response = await fetch(`/api/cart/items/reman-and-deposit-update-by-spare-part-code`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        body: JSON.stringify({ code: sparePartCode, quantity }),
        method: "POST"
    });
    const data = await response.json();
    if (!data.isSuccess) {
        console.error(JSON.stringify(data))
    }
}

export const updateCartKitItem = async (code: string, quantity: number, bundleCode: string, kitCode: string, serialNumber: string | null) => {
    const response = await fetch(`/api/cart/kit-items/update`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        body: JSON.stringify({ code, quantity, bundleCode, kitCode, serialNumber }),
        method: "POST"
    });
    const data = await response.json();
    if (!data.isSuccess) {
        console.error(JSON.stringify(data))
    } else {
        await updateCart();
    }
}

export const updateMultipleCartItems = async (items: { code: string, quantity: number }[]) => {
    const response = await fetch(`/api/cart/items/updateMultiple`, {
        headers: {
            "Content-Type": "application/json",
            "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
        },
        body: JSON.stringify(items),
        method: "POST"
    });
    const data = await response.json();
    if (!data.isSuccess) {
        console.error(JSON.stringify(data))
    } else {
        await updateCart();
    }
}

    export const updateMultipleCartKitItems = async (items: { code: string, quantity: number, bundleCode: string, kitCode: string, serialNumber: string | null }[]) => {
        const response = await fetch(`/api/cart/kit-items/updateMultiple`, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            },
            body: JSON.stringify(items),
            method: "POST"
        });
        const data = await response.json();
        if (!data.isSuccess) {
            console.error(JSON.stringify(data))
        } else {
            await updateCart();
        }
}