import { usePromise } from "../../hooks";

type BranchInventoryModel = {
    variantCode: string | null;
    quantity: number;
    code: string | null;
    displayName: string | null;
    name: string | null;
    isSelected: boolean;
    hideQuantity: boolean;
    hasEnoughStock: boolean;
}

export const Inventories = ({ itemCode, itemDisplayName }: { itemCode: string, itemDisplayName: string }) => {
    const translations = (window as any).app.preloadState.translation;

    const { data, isLoading } = usePromise(async () => {
        const response = await fetch('/api/inventory/branch/byVariant/' + itemCode, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            return [];
        }
        const data = await response.json();
        return (data as BranchInventoryModel[]).sort((a, b) => (a.displayName ?? "").localeCompare(b.displayName ?? ""));
    }, [itemCode]);

    const selectBranch = async (code: string) => {
        const response = await fetch('/api/inventory/branch/' + code, {
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            },
            method: 'PUT'
        });
        if (!response.ok) {
            console.error(await response.text());
        } else {
            location.reload();
        }
    }

    return <div>
        <div className="itemData">#{itemCode} {itemDisplayName}</div>
        <div className="inventories">
            {!isLoading && data!.map(inv => <div key={inv.code}>
                <div className="data">
                    <div className="name">{inv.displayName}</div>
                    <span className={`firstLetterCapital inventoryIndicator ${inv.hasEnoughStock ? "check" : "close"}`}>
                        {inv.quantity === 0 ? translations["inventoryPopup.notInStock"] : `${inv.hasEnoughStock ? translations["inventoryPopup.inStock"] : translations["inventoryPopup.notInStock"] }`} {inv.hideQuantity ? "" : ` (+${inv.quantity})`}
                    </span>
                </div>
                <div>
                    <a className={`btn btn--primary firstLetterCapital inventoriesButton ${inv.isSelected ? "disabled" : ""}`} onClick={() => !inv.isSelected && selectBranch(inv.code!)}>{translations[inv.isSelected ? "inventoryPopup.selectedFacility" : "inventoryPopup.selectFacility"]}</a>
                </div>
            </div>)}
        </div>
    </div>
}