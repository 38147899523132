export const debounce = <Args extends unknown[], R>(func: (...args: Args) => R, time: number) => {
    let timeout: NodeJS.Timeout;
    let valueToSend: Args | null = null;
    const debounced = (...args: Args) => {
        valueToSend = args;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...valueToSend!);
        }, time);
    };
    debounced.stop = () => clearTimeout(timeout);
    return debounced;
}