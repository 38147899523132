import React from "react";
import { SparePartsList } from "ui";

function App() {
    const state = (window as any).app.preloadState["sparePartVariationList"];
    const total = (window as any).app.preloadState["sparePartVariationTotal"];
    const code = (window as any).app.preloadState["sparePartVariationCode"];

    const callback = async (page: number) => {
        const response = await fetch(`/api/spareparts/${code}?pageNumber=${page}`, {
            method: "GET",
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        return response.json();
    }

    return <SparePartsList items={state} total={total} pageCallback={callback} />
}

export default App;
